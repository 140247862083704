<template>
    <div class="m_wrapper">
        <div class="m_wrapper__header" :class="headerBg && 'bg'">
            <div class="h_left">
                <h1 v-if="pageTitle">{{ pageTitle }}</h1>
                <slot name="h_left"></slot>
            </div>
            <div v-if="$slots.h_center" class="h_center">
                <slot name="h_center"></slot>
            </div>
            <div v-if="$slots.h_right" class="h_right">
                <slot name="h_right"></slot>
            </div>
        </div>
        <div class="m_wrapper__body">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        pageTitle: {
            type: String,
            default: ''
        },
        headerBg: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style lang="scss" scoped>
.m_wrapper{
    height: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
    &__header{
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        &.bg{
            padding-bottom: 0px;
            background: #ffffff;
        }
        h1{
            font-weight: 300;
            font-size: 24px;
            margin-bottom: 0px;
            margin-right: 20px;
        }
        .h_left{
            display: flex;
            align-items: center;
        }
        .h_right{
            display: flex;
            align-items: center;
        }
    }
    &__body{
        flex-grow: 1;
        padding: 15px;
    }
}
</style>